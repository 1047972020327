import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormService } from 'src/app/services/form.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SiteService } from 'src/app/services/site.service';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { RoleService } from 'src/app/services/role.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EmailTemplateService } from 'src/app/services/email-template.service';
import { Location } from '@angular/common';
import { NotificationSendTo } from 'src/app/constant/enums/notificationEnums';
import { NotificationConfiguration } from 'src/app/constant/export-types';
import { User, Role, Form, EmailTemplate, Site } from 'src/app/types/IdNameType'
import { cloneArray } from 'src/app/constant/globalFunction';
import { Router } from '@angular/router';
import { Role as Roles } from 'src/app/models/Role';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-notification-configuration',
  templateUrl: './notification-configuration.component.html',
  styleUrls: ['./notification-configuration.component.css']
})

export class NotificationConfigurationComponent implements OnInit {

  notificationConfiguration: FormGroup<NotificationConfigFormGroup> = new FormGroup<NotificationConfigFormGroup>({
    id: new FormControl<number|null>(null, { nonNullable: true }),
    name: new FormControl<string>('', { validators:[Validators.required] , nonNullable: true }),
    status : new FormControl<number>(0,{nonNullable:true}),
    siteIds : new FormControl<number[]>([],{ validators:[Validators.required] , nonNullable:true}),
    type : new FormControl<number>(1,{nonNullable:true}),
    description : new FormControl<string|null>(null, { nonNullable: false }),
    formIds : new FormControl<number[]>([], { nonNullable: true, validators: [Validators.required] }),
    templateId : new FormControl<number|null>(null,[Validators.required]),
    toList : new FormControl<number|null>(null, {validators:[Validators.required], nonNullable: true }),
    ccList : new FormControl<number|null>(null, { nonNullable: false }),
    bccList : new FormControl<number|null>(null, { nonNullable: false }),
    toRoles : new FormControl<number[]>([],{nonNullable:true}),
    ccRoles : new FormControl<number[]>([],{nonNullable:true}),
    bccRoles : new FormControl<number[]>([],{nonNullable:true}),
    toUsers : new FormControl<string[]>([],{nonNullable:true}),
    ccUsers : new FormControl<string[]>([],{nonNullable:true}),
    bccUsers : new FormControl<string[]>([],{nonNullable:true}),
    emailSubject : new FormControl<string>({value: '', disabled: true},{ validators:[ Validators.required ], nonNullable:true}),
    emailBody : new FormControl<string>({value: '', disabled: true},{ validators:[ Validators.required ], nonNullable:true}),
    multiSelect :  new FormGroup<MultiSelectFormGroup>({
      siteIds : new FormControl<Site[]>([],{ validators:[Validators.required], nonNullable:true}),
      formIds : new FormControl<Form[]>([],{ validators:[Validators.required], nonNullable: true }),
      toRoles : new FormControl<Role[] | null>([],{ nonNullable:true}),
      ccRoles : new FormControl<Role[] | null>([],{ nonNullable:true}),
      bccRoles : new FormControl<Role[] | null>([],{ nonNullable:true}),
      toUsers : new FormControl<User[] | null>([],{ nonNullable:true}),
      ccUsers : new FormControl<User[] | null>([],{ nonNullable:true}),
      bccUsers : new FormControl<User[] | null>([],{ nonNullable:true}),
    }),
  });

  fullRoleList: Role[] = [];
  fullUserList: User[] = [];

  siteDropDownSelect:boolean = false; // to prevent unneccessary getSites api call due to multiselect closeDropDown Bug (it gets called on clicking everywhere, even outside dropDown)
  siteDropdownList: Site[] = [];
  sitesDropDownSettings: IDropdownSettings = {};

  logFormDropdownList: Form[] = [];
  logFormDropdownSetting: IDropdownSettings = {};

  toRoleDropdownList: Role[] = [];
  toRoleDropDownSettings: IDropdownSettings = {};

  ccRoleDropdownList: Role[] = [];
  ccRoleDropDownSettings: IDropdownSettings = {};

  bccRoleDropdownList: Role[] = [];
  bccRoleDropDownSettings: IDropdownSettings = {};

  toUserDropdownList: User[] = [];
  toUserDropDownSettings:IDropdownSettings = {};

  ccUserDropdownList: User[] = [];
  ccUserDropDownSettings: IDropdownSettings = {};

  bccUserDropdownList: User[] = [];
  bccUserDropDownSettings: IDropdownSettings = {};  

  emailTemplateDropdownList: EmailTemplate[] = [];
  emailTemplateDropDownSettings: IDropdownSettings = {};
  studyId: string = '';
  savingInProgress: boolean = false;
  initialMultipleValueFormValues: any;

  constructor( private router: Router, private location: Location, private toastr:ToastrService, private siteApiService:SiteService, private formApiService:FormService, private emailTemplateApi: EmailTemplateService, private userApiService: UserService, private notificationApiService: NotificationService, private roleApiService: RoleService ) {};

  ngOnInit() {
    this.getSessionStorage();
    this.multiSelect();
    this.onChanges();

    if(this.isStateUpdate()){
      // for updating existing notification configuration
      if(this.notificationIdcontrol.value){
        this.notificationApiService.getNotificationById(this.notificationIdcontrol.value).subscribe({
          next: (response) => this.patchNotificationConfiguration(response.responseObject),
          error: (e) => console.error(e)
        });
      }
    }else{
      // for new notification configuration
      this.fetchSiteData();
      this.fetchLogFormData();
      this.fetchRoleData();
      this.emailTemplate();
    };
  }


  getSessionStorage(): void {
    if(Number(sessionStorage.getItem("role")?.toString()) !== Roles.DM ){
      sessionStorage.clear();
      this.router.navigateByUrl('/');
      return;
    };
    this.studyId = sessionStorage.getItem("studyId")?.toString() || '';
    if(this.studyId == ''){
      this.toastr.error('Study Id not found');
    };
  }

  onChanges(){
    this.onSiteIdChange();
    this.onFormIdChange();
    this.onToRoleChange();
    this.onToUserChange();
    this.onCcRoleChange();
    this.onCcUserChange();
    this.onBccRoleChange();
    this.onBccUserChange();
    this.onToListValueChange();
    this.onCcListValueChange();
    this.onBccListValueChange();
    this.onEmailTemplateIdChange();
  };

  onToListValueChange(){
    this.notificationConfiguration.controls.toList.valueChanges.pipe(distinctUntilChanged()).subscribe((value:number|null) => this.resetToListDropDownValues(value));
  }
  onEmailTemplateIdChange(){
    this.notificationConfiguration.controls.templateId.valueChanges.subscribe((value:number|null) => this.setEmailBodyAndSubject(value));
  }

  get emailSubjectControl(){
    return this.notificationConfiguration.controls.emailSubject;
  };

  get emailBodyControl(){
    return this.notificationConfiguration.controls.emailBody;
  };

  // setEmailBodyAndSubject( templateId:number|null){
  //   this.emailSubjectControl.reset({value:this.emailTemplateDropdownList.find((emailTemplate:EmailTemplate) => emailTemplate.id === Number(templateId) )?.subject ??"", disabled : true });
  //   this.emailBodyControl.reset({value:this.emailTemplateDropdownList.find((emailTemplate:EmailTemplate) => emailTemplate.id === Number(templateId) )?.body ??"", disabled : true });
  //   this.emailBodyControl.updateValueAndValidity();
  //   this.emailSubjectControl.updateValueAndValidity();
  // }

  stripHtmlPreserveFormat(html: string): string {
    if (!html) return '';  
    html = html.replace(/<\/?(p|div)>/gi, "\n");
    html = html.replace(/<br\s*\/?>/gi, "\n");
    html = html.replace(/<\/?(b|strong)>/gi, "");
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  setEmailBodyAndSubject(templateId: number | null) {
    let subject = this.emailTemplateDropdownList.find((emailTemplate:EmailTemplate) => emailTemplate.id === Number(templateId) )?.subject ??""
    let selectedTemplate = this.emailTemplateDropdownList.find(
      (emailTemplate: EmailTemplate) => emailTemplate.id === Number(templateId)
    );
  
    let strippedBody = selectedTemplate?.body ? this.stripHtmlPreserveFormat(selectedTemplate?.body ??"") : "";
    let strippedSubject = selectedTemplate?.subject ? this.stripHtmlPreserveFormat(selectedTemplate?.subject??"") : "";
  
    this.emailSubjectControl.reset({ value: strippedSubject, disabled: true });
    this.emailBodyControl.reset({ value: strippedBody, disabled: true });
  
    this.emailBodyControl.updateValueAndValidity();
    this.emailSubjectControl.updateValueAndValidity();
  }
  
  stripHtml(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }
  
  private setValidators() {
    // here we are dynamically changing validators because only one of the ToRoles or ToUsers selection is required at a time 
    if(this.toListControl.value == 1 ){
      this.multiSelectToRoleControl.setValidators([Validators.required]);
      this.notificationConfiguration.controls.toRoles.setValidators([Validators.required]);
      this.notificationConfiguration.controls.multiSelect.controls.toUsers.clearValidators();
      this.toUsersControl.clearValidators();
      this.multiSelectToRoleControl.updateValueAndValidity();
      this.notificationConfiguration.controls.toRoles.updateValueAndValidity();
      this.notificationConfiguration.controls.multiSelect.controls.toUsers.updateValueAndValidity();
      this.toUsersControl.updateValueAndValidity();
    } else if (this.toListControl.value == 2) {
      this.notificationConfiguration.controls.multiSelect.controls.toUsers.setValidators([Validators.required]);
      this.toUsersControl.setValidators([Validators.required]);
      this.multiSelectToRoleControl.clearValidators();
      this.notificationConfiguration.controls.toRoles.clearValidators();
      this.notificationConfiguration.controls.multiSelect.controls.toUsers.updateValueAndValidity();
      this.toUsersControl.updateValueAndValidity();
      this.multiSelectToRoleControl.updateValueAndValidity();
      this.notificationConfiguration.controls.toRoles.updateValueAndValidity();
    }
  }

  resetToListDropDownValues(value:number|null){
    this.notificationConfiguration.controls.toRoles.setValue([]);
    this.multiSelectToRoleControl.setValue([]);
    this.toUsersControl.setValue([]);
    this.notificationConfiguration.controls.multiSelect.controls.toUsers.setValue([]);
    this.setValidators();
  }

  onCcListValueChange(){
    this.notificationConfiguration.controls.ccList.valueChanges.pipe(distinctUntilChanged()).subscribe((value:number|null) => this.resetCcListDropDownValues(value));
  }
  
  resetCcListDropDownValues(value:number|null){
    this.notificationConfiguration.controls.ccRoles.setValue([]);
    this.multiSelectCcRoleControl.setValue([]);
    this.ccUsersControl.setValue([]);
    this.notificationConfiguration.controls.multiSelect.controls.ccUsers.setValue([]);
  }

  onBccListValueChange(){
    this.notificationConfiguration.controls.bccList.valueChanges.pipe(distinctUntilChanged()).subscribe((value:number|null) => this.resetBccListDropDownValues(value));
  }
  
  resetBccListDropDownValues(value:number|null){
    this.notificationConfiguration.controls.bccRoles.setValue([]);
    this.multiSelectBccRoleControl.setValue([]);
    this.bccUsersControl.setValue([]);
    this.notificationConfiguration.controls.multiSelect.controls.bccUsers.setValue([]);

  }

  onBccUserChange() {
    this.notificationConfiguration.controls.multiSelect.controls.bccUsers.valueChanges.pipe(distinctUntilChanged()).subscribe((users:User[]|null) => this.setBccUserValues(users));
  };
  
  setBccUserValues(users: User[]|null): void {
    this.bccUsersControl.setValue(users ? users.map((user:User) => user.id ) : []);
    this.ccUserDropdownList = this.fullUserList.filter((user: User) => !users?.some((u: User) => u.id === user.id) && !this.multiSelectToUsersControl.value?.filter((u: User) => u.id === user.id).length);
    this.toUserDropdownList = this.fullUserList.filter( (user:User) => !users?.some( (u:User) => u.id === user.id) && !this.multiSelectCcUsersControl.value?.filter((u: User )=> u.id === user.id).length);
  };
  
  onBccRoleChange() {
    this.multiSelectBccRoleControl.valueChanges.pipe(distinctUntilChanged()).subscribe((roles:Role[]|null) => this.setBccRoleValues(roles));
  };
  
  get multiSelectToRoleControl(){
    return this.notificationConfiguration.controls.multiSelect.controls.toRoles;
  };

  get multiSelectCcRoleControl(){
    return this.notificationConfiguration.controls.multiSelect.controls.ccRoles;
  };

  get multiSelectBccRoleControl(){
    return this.notificationConfiguration.controls.multiSelect.controls.bccRoles;
  }

  
  get multiSelectToUsersControl(){
    return this.notificationConfiguration.controls.multiSelect.controls.toUsers;
  };

  get multiSelectCcUsersControl(){
    return this.notificationConfiguration.controls.multiSelect.controls.ccUsers;
  };

  get multiSelectBccUsersControl(){
    return this.notificationConfiguration.controls.multiSelect.controls.bccUsers;
  }

  get multiSelectFormIdsControl(){
    return this.notificationConfiguration.controls.multiSelect.controls.formIds;
  }

  get multiSelectSiteIdsControl(){
    return this.notificationConfiguration.controls.multiSelect.controls.siteIds;
  }

  get notificationIdcontrol(){
    return this.notificationConfiguration.controls.id;
  }

  get toUsersControl(){
    return this.notificationConfiguration.controls.toUsers;
  }

  get ccUsersControl(){
    return this.notificationConfiguration.controls.ccUsers;
  }

  get bccUsersControl(){
    return this.notificationConfiguration.controls.bccUsers;
  }

  setBccRoleValues(roles: Role[]|null): void {
    this.notificationConfiguration.controls.bccRoles.setValue(roles ? roles.map((role:Role) => role.id) : []);
    this.ccRoleDropdownList = this.fullRoleList.filter( (role:Role) => !(roles?.filter( (r:Role) => r.id === role.id).length) && !this.multiSelectToRoleControl.value?.filter( (r:Role) => r.id === role.id).length);
    this.toRoleDropdownList = this.fullRoleList.filter( (role:Role) => !roles?.filter( (r:Role) => r.id === role.id).length && !this.multiSelectCcRoleControl.value?.filter( (r:Role) => r.id === role.id).length);
  };
  
  onCcUserChange() {
    this.notificationConfiguration.controls.multiSelect.controls.ccUsers.valueChanges.pipe(distinctUntilChanged()).subscribe((users:User[]|null) => this.setCcUserValues(users));
  };
  
  setCcUserValues(users: User[]|null): void {
    this.ccUsersControl.setValue(users ? users.map((user:User) => user.id) : []);
    this.bccUserDropdownList = this.fullUserList.filter((user: User) => !users?.some((u: User) => u.id === user.id) && !this.multiSelectToUsersControl.value?.filter((u: User) => u.id === user.id).length);
    this.toUserDropdownList = this.fullUserList.filter( (user:User) => !users?.some( (u:User) => u.id === user.id) && !this.multiSelectBccUsersControl.value?.filter((u: User )=> u.id === user.id).length);
  };
  
  onCcRoleChange() {
    this.multiSelectCcRoleControl.valueChanges.pipe(distinctUntilChanged()).subscribe((roles:Role[]|null) => this.setCcRoleValues(roles));
  };
  
  setCcRoleValues(roles: Role[]|null): void {
    this.notificationConfiguration.controls.ccRoles.setValue(roles ? roles.map((role:Role) => role.id) : []);
    this.bccRoleDropdownList = this.fullRoleList.filter( (role:Role) => !roles?.filter( r => r.id === role.id).length && !this.multiSelectToRoleControl.value?.some( (r:Role) => r.id === role.id));
    this.toRoleDropdownList = this.fullRoleList.filter( (role:Role) => !roles?.filter( r => r.id === role.id).length && !this.multiSelectBccRoleControl.value?.some( (r:Role) => r.id === role.id));
  };

  onToUserChange(){
    this.notificationConfiguration.controls.multiSelect.controls.toUsers.valueChanges.pipe(distinctUntilChanged()).subscribe((users:User[]|null) => this.setToUserValues(users));
  };

  setToUserValues(users:User[]|null): void{
    this.toUsersControl.setValue(users ? users.map((user:User) => user.id):[]);
    this.bccUserDropdownList = this.fullUserList.filter((user: User) => !users?.some((u: User) => u.id === user.id) && !this.multiSelectCcUsersControl.value?.filter((u: User) => u.id === user.id).length);
    this.ccUserDropdownList = this.fullUserList.filter( (user:User) => !users?.some( (u:User) => u.id === user.id) && !this.multiSelectBccUsersControl.value?.filter((u: User )=> u.id === user.id).length);
  };

  onToRoleChange(){
    this.multiSelectToRoleControl.valueChanges.pipe(distinctUntilChanged()).subscribe((roles:Role[]|null) => this.setToRoleValues(roles));
  };

  setToRoleValues(roles:Role[]|null): void{
    this.notificationConfiguration.controls.toRoles.setValue(roles ? roles.map((role:Role) => role.id): []);
    this.ccRoleDropdownList = this.fullRoleList.filter( role => !roles?.filter( r => r.id === role.id).length && !this.multiSelectBccRoleControl.value?.filter( r => r.id === role.id).length);
    this.bccRoleDropdownList = this.fullRoleList.filter( role => !roles?.filter( r => r.id === role.id).length && !this.multiSelectCcRoleControl.value?.filter( r => r.id === role.id).length);
  };

  onFormIdChange(){
    this.notificationConfiguration.controls.multiSelect.controls.formIds.valueChanges.pipe(distinctUntilChanged()).subscribe((formIds:Form[]|null) => this.setFormIdValues(formIds));
  };

  setFormIdValues(formIds:Form[]|null): void{
    this.notificationConfiguration.controls.formIds.setValue( formIds ? formIds.map((form:Form) => form.id) : []);
  };

  onSiteIdChange(){
    this.multiSelectSiteIdsControl.valueChanges.pipe(distinctUntilChanged()).subscribe((siteIds:Site[]|null) => this.setSiteIdValues(siteIds));
  };

  setSiteIdValues(siteIds:Site[]|null): void{
    this.notificationConfiguration.controls.siteIds.setValue( siteIds ? siteIds.map((site:Site) => site.id) : []);
    this.notificationConfiguration.controls.multiSelect.controls.toUsers.setValue([]);
    this.notificationConfiguration.controls.multiSelect.controls.bccUsers.setValue([]);
    this.notificationConfiguration.controls.multiSelect.controls.ccUsers.setValue([]);
    this.toUsersControl.setValue([]);
    this.ccUsersControl.setValue([]);
    this.bccUsersControl.setValue([]);
  }

  get toListControl(){
    return this.notificationConfiguration.controls.toList;
  };

  get ccListControl(){
    return this.notificationConfiguration.controls.ccList;
  };

  get bccListControl(){
    return this.notificationConfiguration.controls.bccList;
  };

  get selectedSiteIds() {
    return this.notificationConfiguration.controls.siteIds.value;
  }

  isStateUpdate(): boolean {
    let state = this.location.getState();
    if (state && typeof state === 'object' && 'id' in state && typeof state.id === 'number' && state.id > 0) {
      if (state.id && state.id > 0) {
        this.notificationIdcontrol.setValue(state.id);
        return true;
      };
    };
    return false;
  };

  private patchNotificationConfiguration(notification: NotificationConfiguration): void {
    const responseObject = { ...notification};
    this.notificationConfiguration.patchValue(responseObject);
    this.fetchLogFormData(false,responseObject);
    this.emailTemplate(false,responseObject);
    this.fetchSiteData(false,responseObject);
    this.setOldValuesInRolesMultiSelectDropDown(responseObject);
  }
  
  private setOldValuesInRolesMultiSelectDropDown(responseObject: NotificationConfiguration) {
    this.fetchRoleData(false,responseObject);
  }
  getUsersToSet(users: string[]|null): User[] {  // map(userId => userId).
    return this.fullUserList.filter((user:User) => users?.includes(user.id))??[];
  };

  getRolesToSet(roles:number[]|null):Role[]{ // map(roleId => roleId)
    return this.fullRoleList.filter((role:Role) => roles?.includes(role.id)) ?? [];
  }

  // Site Dropdown Control
  fetchSiteData( newNotification:boolean = true, patchedData :NotificationConfiguration|null = null) {
    this.siteApiService.getSiteIdName().subscribe({
      next: (res) => {
        if (res.status === 0) {
          if (res.responseObject) {
            this.siteDropdownList = res.responseObject;
            if (newNotification === false && patchedData) {
              this.multiSelectSiteIdsControl.setValue(this.siteDropdownList.filter((site:Site) => patchedData.siteIds?.includes(site.id)), {emitEvent:false});
              this.fetchUsersBySelectedSites(false,patchedData);
            };
          };
        } else {
          this.toastr.error(res.message);
        };
      },
      error: (e) => console.error(e)
    });
  };

  // Log Form Dropdown Control
  fetchLogFormData( newNotification:boolean = true, patchedData :NotificationConfiguration|null = null) {
    this.formApiService.getLogFormsData().subscribe({
      next:(res) => {
        if(res.status == 0){
          if(res.responseObject){
            this.logFormDropdownList = res.responseObject; 
            if(newNotification === false && patchedData){  // .map(formId => formId)
              this.multiSelectFormIdsControl.setValue(this.logFormDropdownList.filter((form:Form) => patchedData.formIds?.includes(form.id)));
            };
          };
        }else{
          this.toastr.error(res.message)
        };
      },
      error: (error) => {
        console.error(error)
      }
    });  
  };

  // To Role Dropdown Control
  fetchRoleData( newNotification:boolean = true, responseObject :NotificationConfiguration|null = null) {
    this.roleApiService.getRoleList().subscribe({
      next:(res) => {
        if(res.status == 0){
          if(res.responseObject){
            this.setRolesDropDownListData(res.responseObject);
            this.setOldValueInRoleDropDownList(newNotification, responseObject);
          }
        }else{
          this.toastr.error(res.message)
        };
      },
      error: (error) =>  console.error(error)
    }); 
  }

  private setOldValueInRoleDropDownList(newNotification: boolean, responseObject: NotificationConfiguration | null) {
    if (newNotification === false && responseObject) {
      if (responseObject.toList === NotificationSendTo.ROLES) {
        this.multiSelectToRoleControl.setValue(this.getRolesToSet(responseObject.toRoles));
      }
      if (responseObject.bccList === NotificationSendTo.ROLES) {
        this.multiSelectBccRoleControl.setValue(this.getRolesToSet(responseObject.bccRoles));
      }
      if (responseObject.ccList === NotificationSendTo.ROLES) {
        this.multiSelectCcRoleControl.setValue(this.getRolesToSet(responseObject.ccRoles));
      }
    };
  }

  private setRolesDropDownListData(roles: Role[]) {
    this.toRoleDropdownList = cloneArray(roles);
    this.ccRoleDropdownList = cloneArray(roles);
    this.bccRoleDropdownList = cloneArray(roles);
    this.fullRoleList = cloneArray(roles);
  }

  onSiteDropDownClose() {
    if(this.siteDropDownSelect){
      if( this.selectedSiteIds.length > 0 ){
        this.fetchUsersBySelectedSites();
      }else{
        this.setUsersDropdownListAndValue([]);
      }
      this.siteDropDownSelect = false;
    }
  };

  private fetchUsersBySelectedSites(newNotification:boolean = true, NotificationObject :NotificationConfiguration|null = null) {
    this.userApiService.getUserBySites(this.selectedSiteIds).subscribe({
      next: (res) => {
        if (res.status === 0) {
          this.setUsersDropdownListAndValue(res.responseObject);
          if(NotificationObject && newNotification === false){ 
            this.updateOldUserValueInMultiSelectDropDown(NotificationObject);
          };
        } else {
          this.setUsersDropdownListAndValue([]);
          this.toastr.info(res.message);
        }
      },
      error: (error) => { this.toastr.error(error.message); console.error('Error While getting Users List', error); },
    });
  }

  private updateOldUserValueInMultiSelectDropDown(NotificationObject: NotificationConfiguration) {
    if (NotificationObject.toList === NotificationSendTo.USERS) {
      this.multiSelectToUsersControl.setValue(this.getUsersToSet(NotificationObject.toUsers));
    };
    if (NotificationObject.bccList === NotificationSendTo.USERS) {
      this.multiSelectBccUsersControl.setValue(this.getUsersToSet(NotificationObject.bccUsers));
    };
    if (NotificationObject.ccList === NotificationSendTo.USERS) {
      this.multiSelectCcUsersControl.setValue(this.getUsersToSet(NotificationObject.ccUsers));
    };
  }

  setUsersDropdownListAndValue(list:User[]){
    this.toUsersControl.setValue([]);
    this.ccUsersControl.setValue([]);
    this.bccUsersControl.setValue([]);
    this.toUserDropdownList = cloneArray(list);
    this.ccUserDropdownList = cloneArray(list);
    this.bccUserDropdownList = cloneArray(list);
    this.fullUserList = cloneArray(list);
  };

  // Email Template 
  emailTemplate( newNotification:boolean = true, responseObject :NotificationConfiguration|null = null) {
    this.emailTemplateApi.getEmailTemplate().subscribe((res:any) => {
      if (res.status === 0) {
        if (res.responseObject) {
          this.emailTemplateDropdownList = res.responseObject;
          if(newNotification === false && responseObject){
            this.notificationConfiguration.controls.templateId.setValue(responseObject.templateId);
          };
        };
      }else {
        this.toastr.error(res.message);
      };
    }, error => {
      console.error(error);
    });
  }

  onSave() {
    var body = Object.assign({}, this.notificationConfiguration.value);
    delete body.multiSelect;
    body.ccList = this.getVerifiedListValue(body.ccList, body.ccRoles, body.ccUsers);
    body.bccList = this.getVerifiedListValue(body.bccList, body.bccRoles, body.bccUsers);
    if (this.notificationConfiguration.valid){
      this.savingInProgress = true;
      this.notificationApiService.saveNotificationData(body).subscribe({
        next: (response) => {
          if(response.status === 0){
            this.notificationIdcontrol.setValue(response.responseObject.id);
            this.toastr.success('Saved Successfully');
            this.savingInProgress = false;
            this.router.navigateByUrl('/layout/notification');
          }else{
            this.toastr.error(response.message);
            this.savingInProgress = false;
          }
        },
        error: (error) => {
          this.toastr.error('Error saving notification configuration');
          this.savingInProgress = false;
          console.error(error.message);
        }
      });
    } else {
      this.toastr.error('Fill Required Fields');
      this.notificationConfiguration.markAllAsTouched();
    }
  }
  
  getVerifiedListValue(listType: number | null | undefined, roles : number[] | undefined, users: string[] | undefined): number | null {
    // this method returns the value null if no value is selected in the dropdown of the corresponding dropdown
    if(listType == 1){
      if(roles === undefined || roles.length === 0){
        return null;
      }else{
        return 1;
      };
    }else if(listType == 2){
      if(users === undefined || users.length === 0){
        return null;
      }else{
        return 2;
      };
    };
    return null;
  };

  onUpdate() {
    var body = Object.assign({}, this.notificationConfiguration.value);
    delete body.multiSelect;
    body.ccList = this.getVerifiedListValue(body.ccList, body.ccRoles, body.ccUsers);
    body.bccList = this.getVerifiedListValue(body.bccList, body.bccRoles, body.bccUsers);
    if (this.notificationConfiguration.valid){
      this.savingInProgress = true;
      this.notificationApiService.updateConfiguration(body).subscribe({
        next: (response) => {
          if(response.status === 0){
            this.notificationIdcontrol.setValue(response.responseObject.id);
            this.savingInProgress = false;
            this.toastr.success('Saved Successfully');
            this.router.navigateByUrl('/layout/notification');
          }else{
            this.toastr.error(response.message);
            this.savingInProgress = false;
          }
        },
        error: (error) => {
          this.toastr.error('Error Updating Notification configuration');
          this.savingInProgress = false;
          console.error(error.message);
        }
      });
    } else {
      this.toastr.error('Fill Required Fields');
      this.notificationConfiguration.markAllAsTouched();
    };
  }

  onCancel() {
    this.router.navigateByUrl('/layout/notification');
  };

  multiSelect(){
    this.siteDropdownList = [];
    this.sitesDropDownSettings = {
      singleSelection: false,
      idField: 'id', 
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect',
      itemsShowLimit: 2,
      allowSearchFilter: true,
    };

    this.logFormDropdownList = [];
    this.logFormDropdownSetting = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.toRoleDropdownList = [];
    this.toRoleDropDownSettings = {
      singleSelection: false,
      idField: 'id', 
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.ccRoleDropdownList = [];
    this.ccRoleDropDownSettings = {
      singleSelection: false,
      idField: 'id', 
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.bccRoleDropdownList
    this.bccRoleDropDownSettings = {
      singleSelection: false,
      idField: 'id', 
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.toUserDropdownList = [];
    this.toUserDropDownSettings = {
      singleSelection: false,
      idField: 'id', 
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect',
      itemsShowLimit: 2,
      allowSearchFilter: true
    }; 

    this.ccUserDropdownList = [];
    this.ccUserDropDownSettings = {
      singleSelection: false,
      idField: 'id', 
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect',
      itemsShowLimit: 2,
      allowSearchFilter: true
    }; 

    this.bccUserDropdownList = [];
    this.bccUserDropDownSettings = {
      singleSelection: false,
      idField: 'id', 
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.emailTemplateDropdownList
    this.emailTemplateDropDownSettings = {
      singleSelection: false,
      idField: 'id', 
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
  };
};

type NotificationConfigFormGroup = {
  id: FormControl<number|null>;
  name: FormControl<string>;
  status : FormControl<number>;
  siteIds : FormControl<number[]>;
  type : FormControl<number>;
  description : FormControl<string|null>;
  formIds : FormControl<number[]>;
  templateId : FormControl<number|null>;
  toList : FormControl<number|null>;
  ccList : FormControl<number|null>;
  bccList : FormControl<number|null>;
  toRoles : FormControl<number[]>;
  ccRoles : FormControl<number[]>;
  bccRoles : FormControl<number[]>;
  toUsers : FormControl<string[]>;
  ccUsers : FormControl<string[]>;
  bccUsers : FormControl<string[]>;
  multiSelect : FormGroup<MultiSelectFormGroup>;
  emailSubject : FormControl<string>;
  emailBody : FormControl<string>;
};

type MultiSelectFormGroup = {
  siteIds : FormControl<Site[]|null>;
  formIds : FormControl<Form[]|null>;
  toRoles : FormControl<Role[]|null>;
  ccRoles : FormControl<Role[]|null>;
  bccRoles: FormControl<Role[]|null>;
  toUsers : FormControl<User[]|null>;
  ccUsers : FormControl<User[]|null>;
  bccUsers: FormControl<User[]|null>;
};